<template>
  <div class="content-wrapper">
    <div class="content-box">
      <el-form
        ref="passwordForm"
        :model="user"
        :rules="rules"
        @keyup.enter.native="checkForm"
      >
        <h1>Passwort zurücksetzen</h1>

        <el-form-item label="Passwort wählen" prop="password">
          <el-input v-model="user.password" type="password"></el-input>
        </el-form-item>

        <el-form-item label="Passwort wiederholen" prop="passwordConfirmation">
          <el-input
            v-model="user.passwordConfirmation"
            type="password"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            class="fullwidth-button"
            :loading="loading"
            @click="checkForm"
          >
            Password zurücksetzen
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import UserRepository from "@/repositories/user_repository.js"
import FormMixin from "@/mixins/form.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import utils from "@/utils"

export default {
  metaInfo: {
    title: "Passwort zurücksetzen"
  },
  mixins: [FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      user: {
        token: this.$route.query.token
      },
      rules: {
        password: [
          {
            required: true,
            message: "Bitte Passwort angeben",
            trigger: "blur"
          },
          {
            min: 10,
            message: "Das Passwort muss mind. 10 Zeichen haben",
            trigger: "change"
          }
        ],
        passwordConfirmation: [
          {
            required: true,
            message: "Bitte Passwort wiederholen",
            trigger: "blur"
          },
          { validator: this.validatePasswords, trigger: "blur" }
        ]
      },
      loading: false
    }
  },
  methods: {
    validatePasswords(rule, value, callback) {
      if (value !== this.user.password) {
        callback(new Error("Die Passwörter stimmen nicht überein"))
      } else {
        callback()
      }
    },
    checkForm() {
      this.$refs["passwordForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.loading = true

      try {
        await UserRepository.resetPassword(this.user.token, this.user.password)
        this.loading = false
        this.$router.push({ name: "LoginPage" })
        this.$message({
          showClose: true,
          message:
            "Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun erneut einloggen.",
          type: "success",
          duration: 10000
        })
        utils.addErrorBreadcrumb({
          category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
          action: "Changed password", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
          metadata: {
            user_type: this.$store.getters["auth/userType"],
            user_id: this.$store.getters["auth/userData"].id,
            user_email: this.$store.getters["auth/userData"].email
          } // key/value metadata in <string, string> format
        })
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.content-box {
  width: 400px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h1 {
  text-align: center;
}

.fullwidth-button {
  width: 100%;
}
</style>
